import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import { T } from "components/ui/Typography";
import { exportToCRMProviders } from "config/constants";
import useAllRecords from "hooks/useAllRecords";
import generic from "i18n/generic";
import Integration from "models/Integration";
import { defineMessages, FormattedMessage } from "react-intl";
import { FilterType as PipelineFilterType } from "screens/Frontoffice/screens/DataTables/screens/Pipeline/types";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { ReportType } from "screens/Frontoffice/screens/DataTables/shared/utils";

import ExportCrm, { AccountMappingExportingParameters } from "./ExportCrm";
import ExportCsv from "./ExportCsv";
import ExportXlsx from "./ExportXlsx";

export type Parameters = {
  // For Account Mapping
  partnership_id?: number;
  // For Account Mapping and Mapping 360
  columns?: string[];
  sort?: object[];
  // For Pipeline, Account Mapping and Mapping 360
  filters: PipelineFilterType[] | FilterType[];
};

export type ExportOptionsProps = {
  // For Account Mapping
  isDemo?: boolean;
  disabledExportToCRM?: boolean;
  openModal?: (integration: Integration) => void;
  numberOfRecordsToExport?: number | null;
  setNumberOfRecordsToExport?: (number: number) => void;
  // For Account Mapping and Mapping 360
  disabledExportToCSV?: boolean;
  disabledExportToXlsx?: boolean;
  // For Pipeline, Account Mapping and Mapping 360
  reportType: ReportType;
  parameters: Parameters;
  isCsvGenerating: boolean;
  isCsvReady: boolean;
  downloadCsv: () => void;
  selectedRowsCount?: number;
} & WithUserProfile;

const ExportOptions = ({
  isDemo,
  disabledExportToCRM = true,
  openModal = (integration: Integration) => {},
  numberOfRecordsToExport = null,
  setNumberOfRecordsToExport = (number: number) => {},
  disabledExportToCSV = false,
  disabledExportToXlsx = false,
  reportType,
  parameters,
  isCsvGenerating,
  isCsvReady,
  downloadCsv,
  selectedRowsCount = 0,
}: ExportOptionsProps) => {
  const { records: sources } = useAllRecords("integrations");
  const crmSources = sources.filter((integration) =>
    exportToCRMProviders.includes(integration.provider)
  );

  const exportingNewProspects = reportType === ReportType.NEW_LEADS;

  const showExportCrm =
    !isDemo &&
    exportingNewProspects &&
    !disabledExportToCSV &&
    crmSources.length > 0;

  const limitedExportTooltip = (
    <T>
      <FormattedMessage {...generic.notAvailableToFreePlans} />
    </T>
  );

  return (
    <>
      {[
        ReportType.NEW_PROSPECTS_360_XLSX,
        ReportType.COLLABORATE,
        ReportType.NEARBOUND_ACCOUNTS_XLSX,
        ReportType.NEW_ACCOUNT_MAPPING,
      ].includes(reportType) ? (
        <ExportXlsx
          reportType={reportType}
          tooltip={disabledExportToXlsx ? limitedExportTooltip : undefined}
          isDisabled={disabledExportToXlsx}
          isGenerating={isCsvGenerating}
          isReady={isCsvReady}
          download={downloadCsv}
          selectedRowsCount={selectedRowsCount}
        />
      ) : (
        <ExportCsv
          reportType={reportType}
          tooltip={disabledExportToCSV ? limitedExportTooltip : undefined}
          isDisabled={disabledExportToCSV}
          isDemo={isDemo}
          isCsvGenerating={isCsvGenerating}
          isCsvReady={isCsvReady}
          downloadCsv={downloadCsv}
          selectedRowsCount={selectedRowsCount}
        />
      )}
      {showExportCrm && (
        <ExportCrm
          crmDisabled={disabledExportToCRM}
          options={crmSources}
          parameters={parameters as AccountMappingExportingParameters}
          {...{
            openModal,
            numberOfRecordsToExport,
            setNumberOfRecordsToExport,
          }}
        />
      )}
    </>
  );
};

export default withCompanyPermissions<ExportOptionsProps>(
  ["company.manage_partnerships"],
  {
    Fallback: () => null,
  }
)(ExportOptions);

// I18N

const i18n = defineMessages({
  limited: {
    id: "crm.Reports.ExportOptions.limited",
    defaultMessage: "Upgrade to export New Prospects.",
  },
});

export const _private = {
  i18n,
};
