import { ChevronDown } from "components/icons";
import Button from "components/ui/Button";
import DropdownPopover from "components/ui/DropdownPopover";
import useUserProfile from "hooks/useUserProfile";
import useWidth from "hooks/useWidth";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Integration from "models/Integration";
import { PageType } from "models/PageView";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectView as selectView360 } from "redux/mapping360/selectors";
import { selectView as selectViewNearboundAccounts } from "redux/mapping360NearboundAccounts/selectors";
import {
  DataTableType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import UserService from "services/UserService";

import useCsvExport from "../../../hooks/useCsvExport";
import { ReportType } from "../../../utils";
import ExportCrmDialogManager from "../../ExportToCrmDialog";
import { SupportedResources } from "../../ExportToCrmDialog/hooks/useMappingFields";
import ReferDialog, { RecordsToRefer } from "../../ReferDialog";
import AddToPipelineOption from "./AddToPipelineOption";
import ExportOptions, { Parameters } from "./ExportOptions";
import ReferOption from "./ReferOption";
import ReferPartnerStackDialog from "./ReferPartnerStackDialog";
import ReferPartnerStackOption from "./ReferPartnerStackOption";

const getReportType = (
  viewType: DataTableType,
  accountType?: AccountMappingResource,
  pageType?: PageType,
  // TODO: remove after fully migrating to nearbound accounts
  isNearboundAccounts?: boolean
) => {
  if (viewType === DataTableType.MAPPING_360) {
    if (pageType === PageType.mapping360Expand) {
      return ReportType.NEW_PROSPECTS_360_XLSX;
    }
    if (isNearboundAccounts) {
      return ReportType.NEARBOUND_ACCOUNTS_XLSX;
    }
    return ReportType.MY_ACCOUNTS;
  } else {
    switch (accountType) {
      case AccountMappingResource.matches:
        return ReportType.ACCOUNT_MAPPING;
      case AccountMappingResource.matched_accounts:
        return ReportType.NEW_ACCOUNT_MAPPING;
      case AccountMappingResource.shared_accounts:
        return ReportType.NEW_LEADS;
    }
  }
  return null;
};

type Props = {
  // For Account Mapping
  accountType?: AccountMappingResource;
  isSmall?: boolean;
  isDemo?: boolean;
  record?: Record;
  // For Account Mapping and Mapping 360
  viewType: DataTableType;
  parameters: Parameters;
  rows: Record[];
};

const ActionBtn = ({
  record,
  accountType,
  isSmall,
  rows,
  isDemo,
  viewType,
  parameters,
}: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const service = new UserService();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [referModalOpen, setReferModalOpen] = useState<boolean>(false);
  const [
    referPartnerStackModalOpen,
    setReferPartnerStackModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedIntegration,
    setSelectedIntegration,
  ] = useState<Integration | null>(null);
  const [numberOfRecordsToExport, setNumberOfRecordsToExport] = useState<
    number | null
  >(null);
  const width = useWidth();
  const hideLabel = width === "sm" || width === "xs" || false;
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const is360NearboundAccountsUnlocked = payingFeatures.includes(
    PayingFeature.UseNew360
  );
  const view = useSelector(
    is360NearboundAccountsUnlocked ? selectViewNearboundAccounts : selectView360
  );
  const isLeadGen = view?.pageType === PageType.mapping360Expand;

  const handleOpenDropdown = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDropdownOpen(true);
  };
  const handleCloseDropdown = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    setDropdownOpen(false);
  }, []);

  const handleCloseReferModal = () => setReferModalOpen(false);
  const handleOpenReferModal = () => setReferModalOpen(true);

  const handleCloseReferPartnerStackModal = () =>
    setReferPartnerStackModalOpen(false);
  const handleOpenReferPartnerStackModal = () =>
    setReferPartnerStackModalOpen(true);

  const handleOpenExportModal = (integration: Integration) => {
    setSelectedIntegration(integration);
  };
  const handleCloseExportModal = () => setSelectedIntegration(null);

  const hasDisabledExportToCsv = !payingFeatures.includes(
    PayingFeature.ExportToCSV
  );

  const hasLimitedNewProspectsToCSVExport = !payingFeatures.includes(
    PayingFeature.ExportNewProspectToCSV
  );

  const hasLimitedNewProspectsToCRMExport = !payingFeatures.includes(
    PayingFeature.ExportNewProspectToCRM
  );
  const hasOwnPipelinePermission = profile.ownsPipeline;

  const displayPipeline =
    hasOwnPipelinePermission &&
    (accountType === AccountMappingResource.matches ||
      accountType === AccountMappingResource.matched_accounts ||
      accountType === AccountMappingResource.shared_accounts ||
      viewType === DataTableType.MAPPING_360);
  const displayRefer =
    !isLeadGen &&
    hasOwnPipelinePermission &&
    (accountType === AccountMappingResource.matches ||
      accountType === AccountMappingResource.matched_accounts ||
      viewType === DataTableType.MAPPING_360);
  const referPartnerStackPartnership = record?.referPartnerStackPartnership(
    profile.company
  );
  const displayReferPartnerStack =
    (accountType === AccountMappingResource.matches ||
      accountType === AccountMappingResource.matched_accounts) &&
    record &&
    record.isReferViaPartnerStackAvailable(profile.company) &&
    referPartnerStackPartnership &&
    profile.hasPermissions(["company.partner_stack_access"], profile.company);
  const reportType = getReportType(
    viewType,
    accountType,
    view?.pageType,
    is360NearboundAccountsUnlocked && viewType === DataTableType.MAPPING_360
  );
  const hasLimitedNewProspectsToXlsxExport =
    reportType === ReportType.NEW_PROSPECTS_360_XLSX
      ? !payingFeatures.includes(PayingFeature.NewProspects360)
      : false; // TODO: any paying feature to match this for nearbound accounts?
  const displayExport =
    reportType !== null &&
    profile.canManagePartnerships &&
    (!service.isImpersonating ||
      profile.company.allowedAdminsToGenerateReports ||
      service.parsedToken.has_access_to_customer_data);
  const displayButton =
    displayPipeline ||
    displayRefer ||
    displayReferPartnerStack ||
    displayExport;

  const partnershipId = record?.id || null;
  const partner = record?.getPartner(profile);

  const { isGenerating, ready, download } = useCsvExport(
    reportType ?? ReportType.ACCOUNT_MAPPING,
    parameters
  );

  return displayButton ? (
    <>
      <Button
        label={hideLabel ? undefined : <FormattedMessage {...i18n.action} />}
        variant="primary"
        onClick={handleOpenDropdown}
        RightIcon={ChevronDown}
        size={isSmall ? "small" : "medium"}
      />
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={dropdownOpen}
        position="left"
        classes={{ paper: classes.paper }}
      >
        <div onClick={handleCloseDropdown}>
          {displayPipeline && (
            <AddToPipelineOption
              partnerName={partner?.name}
              rows={rows}
              partnershipId={partnershipId}
              disabled={!(rows && rows.length > 0)}
              isDemo={isDemo}
            />
          )}
          {displayRefer && (
            <ReferOption
              partner={partner}
              selectedRowCount={rows.length}
              openModal={handleOpenReferModal}
              sourceType={viewType}
            />
          )}
          {displayReferPartnerStack && (
            <ReferPartnerStackOption
              partner={partner}
              selectedRowCount={rows.length}
              openModal={handleOpenReferPartnerStackModal}
            />
          )}
          {displayExport && (
            <>
              {(displayPipeline ||
                displayReferPartnerStack ||
                displayRefer) && <div className={classes.spacer} />}
              <ExportOptions
                reportType={reportType}
                parameters={parameters}
                disabledExportToCSV={
                  (hasLimitedNewProspectsToCSVExport &&
                    reportType === ReportType.NEW_LEADS) ||
                  hasDisabledExportToCsv
                }
                disabledExportToXlsx={hasLimitedNewProspectsToXlsxExport}
                disabledExportToCRM={hasLimitedNewProspectsToCRMExport}
                isDemo={isDemo}
                data-testid="ecosystem-export-csv-button"
                openModal={handleOpenExportModal}
                numberOfRecordsToExport={numberOfRecordsToExport}
                setNumberOfRecordsToExport={setNumberOfRecordsToExport}
                isCsvGenerating={isGenerating}
                isCsvReady={ready}
                downloadCsv={download}
                selectedRowsCount={rows.length}
              />
            </>
          )}
        </div>
      </DropdownPopover>
      {displayRefer && (
        <ReferDialog
          isOpen={referModalOpen}
          handleClose={handleCloseReferModal}
          recordsToRefer={rows as RecordsToRefer[]}
          partnership={record as Partnership}
          sourceType={viewType}
        />
      )}
      {displayReferPartnerStack && referPartnerStackModalOpen && (
        <ReferPartnerStackDialog
          handleClose={handleCloseReferPartnerStackModal}
          accountToRefer={rows[0]}
          partnerName={partner?.name}
          partnerStackPartnership={referPartnerStackPartnership}
        />
      )}
      {displayExport && selectedIntegration && partnershipId && (
        <ExportCrmDialogManager
          resource={SupportedResources.sharedAccounts}
          partnershipId={partnershipId}
          partnerName={partner?.name}
          onClose={handleCloseExportModal}
          filters={parameters.filters as FilterType[]}
          integrationId={selectedIntegration.id}
        />
      )}
    </>
  ) : null;
};
export default ActionBtn;

/// CSS

const useStyles = makeStyles()((theme) => ({
  spacer: {
    width: "100%",
    height: "1px",
    borderTop: `1px solid ${theme.palette.taupe500}`,
    margin: "8px 0",
  },
  paper: {
    minWidth: 250,
  },
}));

// I18N

const i18n = defineMessages({
  action: {
    id: "Frontoffice.DataTables.ActionBtn.export",
    defaultMessage: "Action",
  },
});
